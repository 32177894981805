import Vue from 'vue'
import '@/utils/url-token-and-lang.js'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//引入图标字体
import  './assets/fonts/iconfont.js'
import  './assets/fonts/iconfontOne.js'
import i18n from '@/utils/i18n.js' //国际化
import '@/utils/utils.js' //公共方法
Vue.use(ElementUI);
import './api/flexible.js'
//引入正则匹配
import './utils/reg.js'
import jquery from 'jquery';
import  echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.prototype.$=jquery;
Vue.config.productionTip = false
export default new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')


