<template>

	<div class="right">

		<template v-if="!isSuccess">

			<div class="title">{{ $t('el.resetPassword.reset') }}</div>

			<div class="form">

				<el-form ref="form" :model="form" :rules="rules">

					<el-form-item prop="phone">

						<el-input
							prefix-icon="el-icon-phone-outline"
							v-model.trim="form.phone"
							:placeholder="$t('el.phoneP')"
							clearable
							maxlength="20"
						></el-input>

					</el-form-item>

					<el-form-item prop="code">

						<div class="flex">

							<el-input
								prefix-icon="el-icon-key"
								v-model.trim="form.code"
								:placeholder="$t('el.verificationCode')"
								style="width: 260px"
								clearable
								maxlength="6"
							>

							</el-input>

							<div class="getcode" v-if="!tip" @click="getCode">
								 {{ $t('el.getVerCode') }}
							</div>

							<div class="getcode" v-else>
								{{ number }}s {{ $t('el.reGetVerCode') }}
							</div>

						</div>

					</el-form-item>

					<el-form-item prop="password">

						<el-input
							prefix-icon="el-icon-lock"
							v-model.trim="form.password"
							:placeholder="$t('el.newPWP')"
							maxlength="12"
							:type="visibleAgain ? 'text' : 'password'"
						>

							<template slot="append">

								<img
									class="showIcon"
									v-if="!visibleAgain"
									@click="visibleAgain = !visibleAgain"
									src="@/assets/images/hide.png"
									alt=""
								/>

								<img
									class="showIcon"
									v-else
									@click="visibleAgain = !visibleAgain"
									src="@/assets/images/show.png"
									alt=""
								/>

							</template>

						</el-input>

					</el-form-item>

					<el-form-item prop="confirmPassword">

						<el-input
							prefix-icon="el-icon-lock"
							v-model.trim="form.confirmPassword"
							:placeholder="$t('el.confirmNewPWP')"
							maxlength="12"
							:type="visible ? 'text' : 'password'"
						>

							<template slot="append">

								<img
									class="showIcon"
									v-if="!visible"
									@click="visible = !visible"
									src="@/assets/images/hide.png"
									alt=""
								/>

								<img
									class="showIcon"
									v-else
									@click="visible = !visible"
									src="@/assets/images/show.png"
									alt=""
								/>

							</template>

						</el-input>

					</el-form-item>

					<el-button
						id="login_btn"
						class="sub-btn"
						:loading="loading"
						size="medium"
						type="primary"
						style="width: 100%; margin-bottom: 30px;margin-top: 12px"
						@click.native.prevent="handRegister"
					>

						<span v-if="!loading">
							{{ $t('el.resetPassword.reset') }}
						</span>

						<span v-else>
							{{ $t('el.resetPassword.reseting') }}...
						</span>

					</el-button>

				</el-form>

				<div class="right-bottom-btn">

					<div @click="$emit('to', 'register')">
						{{ $t('el.register.register') }}
					</div>

					<div @click="$emit('to', 'login')" style="cursor: pointer;">
						{{ $t('el.resetPassword.goLogin') }}
					</div>

				</div>

			</div>

		</template>

		<Success
			v-else
			:title="$t('el.resetPassword.resetSuccess')"
			@to="$emit('to', 'login')"
		></Success>

	</div>

</template>

<script>
import { updatePwd, getResetCode } from '@/api/login'
import Success from './success.vue'
export default {
	components: {
		Success,
	},
	data() {
		const validatePassword = (rule, value, callback) => {
			!value
				? callback(new Error(this.$t('el.newPWP')))
				: !this.pwdReg.test(value)
				? callback(new Error(this.$t('el.tipPWF')))
				: callback()
		}
		const validateCode = (rule, value, callback) => {
			!value ? callback(new Error(this.$t('el.verCodeP'))) : callback()
		}
		const validateConfirmPassword = (rule, value, callback) => {
			!value
				? callback(new Error(this.$t('el.confirmNewPWP')))
				: !this.pwdReg.test(value)
				? callback(new Error(this.$t('el.tipPWF')))
				: this.form.password !== value
				? callback(new Error(this.$t('el.tipPW')))
				: callback()
		}
		const validateCPhone = (rule, value, callback) => {
			!value
				? callback(new Error(this.$t('el.phoneP')))
				: !this.phoneReg.test(value)
				? callback(new Error(this.$t('el.tipPhoneF')))
				: callback()
		}
		return {
			form: {
				username: '',
				password: '',
				confirmPassword: '',
				phone: '',
				code: '',
				uuid: '',
			},
			loading: false,
			rules: {
				password: [
					{
						required: true,
						trigger: 'blur',
						validator: validatePassword,
					},
				],
				confirmPassword: [
					{
						required: true,
						trigger: 'blur',
						validator: validateConfirmPassword,
					},
				],
				code: [
					{
						required: true,
						trigger: 'blur',
						validator: validateCode,
					},
				],
				phone: [
					{
						required: true,
						trigger: 'blur',
						validator: validateCPhone,
					},
				],
			},
			//是否重新获得验证码
			tip: false,
			number: null,
			visibleAgain: false,
			visible: false,
		}
	},
	methods: {
		//处理重置密码
		handRegister() {
			this.$refs['form'].validate((valid) => {
				if (valid) {
					const { phone, password, uuid, code } = this.form
					this.loading = true
					updatePwd({
						code,
						password,
						userName: phone,
						uuid,
					})
						.then((res) => {
							this.loading = false
							this.$message.success(
								this.$t('el.resetPassword.resetSuccess')
							)
							this.isSuccess = true
						})
						.catch((err) => {
							this.loading = false
							return false
						})
				}
			})
		},
		//获得改密码的验证码
		getCode() {
			this.$refs['form'].validateField('phone', (error) => {
				if (!error) {
					getResetCode({
						userName: this.form.phone,
					}).then((res) => {
						this.$message.success(this.$t('el.getVerCodeSuccess'))
						console.log(res, 'res')
						this.tip = true
						this.form.uuid = res.data
						this.number = 60
						this.codeInterval = setInterval(() => {
							if (this.number > 0) {
								this.number--
							} else {
								clearInterval(this.codeInerval)
								this.tip = false
							}
						}, 1000)
					})
				}
			})
		},
	},
}
</script>

<style scoped lang="scss">
.inner {
	width: 100vw;
	height: 100vh;
	position: relative;
	overflow: hidden;
	min-width: 1024px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.right {
	box-sizing: border-box;

	.form {
		width: 400px;
	}

	::v-deep .el-icon-key {
		color: #3e3a3a;
		font-size: 20px;
		font-weight: bold;
		margin-left: -5px;
	}

	.el-input {
		height: 48px;

		::v-deep .el-input__prefix {
			left: 10px;
			line-height: 48px;
			line-height: 57px;
		}

		::v-deep .el-input__inner {
			height: 48px;
			padding: 0 40px;
		}
	}
}

.bg {
	margin: 79px 0 35px;
	text-align: center;
}

.bg img {
	width: 517px;
}

.topic {
	font-size: 18px;
	font-weight: 300;
	line-height: 24px;
	color: #193380;
	text-align: center;
	letter-spacing: 2px;
}

.form {
	margin-top: 25px;
}

.right {
	.flex {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 48px;

		.getcode {
			height: 48px;
			font-size: 14px;
			cursor: pointer;
			color: #1cd2a9;
			line-height: 48px;
			text-align: center;
			padding: 0 5px;
			border-radius: 4px;

			img {
				vertical-align: middle;
				margin-right: 3px;
			}
		}
	}
}

::v-deep .el-input-group__append,
.el-input-group__prepend {
	background-color: white;
	border: 0px;
	position: absolute;
	right: 10px;
	top: 15px;
}

::v-deep .el-input__inner {
	position: relative;
}

input:-webkit-autofill {
	box-shadow: 0 0 0px 1000px white inset !important;
}

::v-deep input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
	-webkit-text-fill-color: #606266 !important;
	box-shadow: 0 0 0px 1000px transparent inset !important;
	background-color: transparent;
	background-image: none;
	transition: background-color 50000s ease-in-out 0s;
}

::v-deep .el-input__prefix {
	left: 2px;

	.el-input__icon {
		font-size: 20px;
	}
}
</style>
