/*
 * @Author: yinian430 
 * @Date: 2020-07-06 18:36:39 
 * @Last Modified by: yinian430
 * @Last Modified time: 2021-04-01 15:16:21
 */
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ElementLocale from 'element-ui/lib/locale'
import { getStore, getUrlkey ,setStore} from "./utils";

// 强制使用中文，不在维护英文
const useLang = 'zh_CN'
// 兼容之前的代码
setStore('language', useLang)
Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: useLang,
    messages: {
        'zh_CN': require('./lang/zh'),
        'en_US': require('./lang/en'),
    },
})
ElementLocale.i18n((key, value) => i18n.t(key, value))
export default i18n;