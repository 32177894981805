import Vue from 'vue'
import Vuex from 'vuex'
import { getTableList } from '@/api/file.js'
Vue.use(Vuex)
import { getData, setData, concatPath, $success, $warning, $error } from '@/utils/save'
import { queryDocument, createDocument, save } from '@/api/file.js'
import { getUrlkey } from "@/utils/utils";
import { getInfo } from '@/api/login'
import $j from 'jquery'
const store = new Vuex.Store({
  state: {
    user: null,
    editableTabsValue: "2",
    editableTabs: [
    ],
    tabIndex: 2,
    //编辑器的值
    editerValue: "",
    //是否改变editor中的值
    isChangeEditror: true,
    //编辑器
    editor: {},
    //显示结果的表格
    showResult: false,
    //当前的操作对象
    curOptions: {},
    //监测结果表格数据
    fileName: "",
    //中断之后不显示检测结果
    showTestResult: true,
    //树状结构选中的节点
    currentNodeKey: '',
    //将树节点存储起来
    treeNode: {},
    //左侧组件的this
    leftNav: {},
    //当前点击行数
    currentClickline: 0,
    //总组件
    totalComponent: {},
    fileDate: [],
    //左边变化的active
    activeName: "",
  },
  mutations: {
    changeUser(state, user) {
      state.user = user
    },
    //添加标签

    addTab(state, tab) {
      //清除修复建议
      state.totalComponent.advice = ''
      state.totalComponent.description = ''
      state.totalComponent.clearForm();
      // state.editor.setValue('')
      const { label, path, type, flag } = tab;
      // debugger;
      //让可以编辑
      state.isChangeEditror = true;

      const cur = state.fileDate.find(item => item.path == path);
      //  console.log(state.fileDate,'state.fileDate')

      //默认项目/默认文件夹/默认文件

      //先查询单个合约，没有的话再创建单个合约
      queryDocument({ contractFileId: cur.id }).then(res => {

        // 取得值,判断这个合约下是否有合约文件值
        const { data } = res;
        //  let fileDate=JSON.parse(getData('fileDate'));
        // console.log(data,'data')
        let fileDate = state.fileDate;
        if (!data) {

          let currdata = fileDate.find(item => item.path === path);

          if (currdata.content) {
            save([
              {
                contractFileId: cur.id,
                contractFileName: label,
                contractType: type,
                code: content
              }
            ]).then(res => {

            })

          }
          else {
            fileDate = fileDate.map(item => {
              if (item.path === path) {
                return { ...item, content: '' }
              };
              return item;
            });

          }
        } else {
          //有数据又分为两种情况，第一种第一次从数据库中拿取数据，第二种更新数据库中的数据
          let currdata = fileDate.find(item => item.path === path);
          //如果有数据则更新数据
          if (currdata.content) {
            const { id, label, type, content } = currdata;
            save([
              {
                contractFileId: id,
                contractFileName: label,
                contractType: type,
                code: content
              }
            ]).then(res => {

            })
            fileDate = fileDate.map(item => {
              if (item.path === path) {
                return { ...item, content }
              };
              return item;
            });


          } else {
            fileDate = fileDate.map(item => {
              if (item.path === path) {
                return { ...item, content: data.code }
              };
              return item;
            });

          }
        }
        //  setData('fileDate',JSON.stringify(fileDate))
        state.fileDate = fileDate.map(v => {
          if (v.path === path) {
            return {
              ...v,
              defaultContract: data ? data.defaultContract : false
            }
          }
          return { ...v }
        });
        state.curOptions.defaultContract = data ? data.defaultContract : false;
        //判断是否有这个标签了
        const haveTab = state.editableTabs.some((item) => item.name === path);
        if (!haveTab) {
          state.editableTabs.push({
            title: label,
            name: path,
          });
        }
        //  const { content } = JSON.parse(getData('fileDate')).find((item) => item.path === path);
        const { content } = state.fileDate.find((item) => item.path === path);
        //获得数值
        state.editableTabsValue = path;
        state.editor.setValue(content);
        state.editor.setReadOnly(false);
        state.editor.gotoLine(1)


      })
      const scrol = $j(".ace_scrollbar.ace_scrollbar-v ");
      scrol.unbind();
      $j(".ace_line").css({ background: "transparent" })
    },
    //点击table
    tabClick(state, tab) {

      //让可以编辑

      state.isChangeEditror = true;
      // const { content,path } =JSON.parse(getData('fileDate')).find((item) => item.path == tab.name);
      const { content, path } = state.fileDate.find((item) => item.path == tab.name);
      state.editableTabsValue = tab.name;

      state.editor.setValue(content);
      state.editor.gotoLine(1);

      //这里需要改变
      // state.editerValue=content;
      //这个时候是不想触发editor change里面的事件的
      state.currentNodeKey = tab.name;
      state.treeNode.setCurrentKey(tab.name);
      // state.curOptions=JSON.parse(getData('fileDate')).find((item) => item.path == tab.name);
      state.curOptions = state.fileDate.find((item) => item.path == tab.name);
      state.leftNav.curOption = state.curOptions;
      //找一下展开了这个节点没
      let expand = JSON.parse(getData('expandedKeys'));
      if (!expand.some(item => item === tab.name)) {
        expand = [...expand, ...concatPath(tab.name)]
      }
      state.leftNav.expandedKeys = expand;
      state.treeNode.setCurrentKey(tab.name);
      setData('expandedKeys', JSON.stringify(expand));
      const scrol = $j(".ace_scrollbar.ace_scrollbar-v ");
      scrol.unbind();
      $j(".ace_line").css({ background: "transparent" })
      //清除修复建议
      state.totalComponent.advice = ''
      state.totalComponent.description = ''
      state.totalComponent.clearForm();
      // 用户切换类型了
      // const node=JSON.parse(getData('fileDate')).find((item) => item.path == path.split('/')[0]); 
      const node = state.fileDate.find((item) => item.path == path.split('/')[0]);

      state.totalComponent.showName = node.type !== 'fabric'
    },
    //移除tabs
    removeTab(state, targetName) {

      let tabs = state.editableTabs;
      let activeName = state.editableTabsValue;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }

      state.editableTabsValue = activeName;
      //郭这里了

      // state.treeNode.setCurrentKey(activeName);
      state.activeName = activeName;

      let selectFile = state.fileDate.filter((item) => item.path === activeName);
      state.totalComponent.files = selectFile.map((item) => ({
        label: item.label,
        value: item.path,

      }));
      state.curOptions = state.fileDate.find((item) => item.path == activeName);
      state.leftNav.curOption = state.curOptions;
      state.totalComponent.showName = state.curOptions.type !== 'fabric'


      state.editableTabs = tabs.filter((tab) => tab.name !== targetName);
      let expand = JSON.parse(getData('expandedKeys'));
      if (!expand.some(item => item === activeName)) {
        expand = [...expand, ...concatPath(activeName)]
      }
      state.leftNav.expandedKeys = expand;
      state.treeNode.setCurrentKey(activeName);
      setData('expandedKeys', JSON.stringify(expand));

      if (state.editableTabs.length) {
        // let { content } =JSON.parse(getData('fileDate')).find((item) => item.path == activeName);
        let { content } = state.fileDate.find((item) => item.path == activeName);
        state.editor.setValue(content)
        state.editor.gotoLine(1);
        state.isChangeEditror = true;
        state.editor.setReadOnly(false);
      } else {
        state.leftNav.curOption = state.curOptions = {};
        state.totalComponent.showName = true
        state.editableTabsValue = '';
        state.totalComponent.files = [];
        state.isChangeEditror = false;
        state.treeNode.setCurrentKey();
        //清除表格数据
        state.totalComponent.clearForm();
        state.totalComponent.curVersion = [];
      }

    },
    //改变 editerValue值
    changeEditorValue(state, payload) {
      state.editerValue = payload;
    },
    //获取editor对象放到全局去
    getEditor(state, payload) {
      state.editor = payload;
    },
    //改变显示结果的状态
    changeShowResult(state, payload) {

      state.showResult = payload;
      //这里需要发送请求拿到后台数据
    },
    //改变当前的操作对象
    changeCurOption(state, payload) {
      state.curOptions = payload;
    },
    //改变中断之后显示检测结果
    changeShowTestResult(state, payload) {
      state.showTestResult = payload;
    },
    //改变editableTabs的值
    changeEditableTabs(state, payload) {

      state.editableTabs = payload;
    },
    changeEditableTab(state, payload) {

      state.editableTabs.push(payload)
    },

    //改变当前选中的节点
    changeCurrentNode(state, payload) {
      state.currentNodeKey = payload
    },
    changeTreeNode(state, payload) {
      state.treeNode = payload;
    },
    //改变左侧组件的this
    changeLeftNav(state, payload) {
      state.leftNav = payload
    },
    changeEditableTabsValue(state, payload) {
      state.editableTabsValue = payload;
    },
    //改变当前行数
    changeCurrentClickline(state, payload) {
      state.currentClickline = payload;
    },
    //改变总组件
    changeTotalComponent(state, payload) {
      state.totalComponent = payload;
    },
    //改变fileDate
    changeFileDate(state, payload) {
      state.fileDate = payload;
    },
    //改变isChangeEditror
    changeIsChangeEditror(state, payload) {
      state.isChangeEditror = payload;
    },
    gotoReset(state, payload) {
      state.editor.setValue(payload);
      state.editor.setReadOnly(false);
      state.editor.gotoLine(1)
    }
  },
  actions: {
  },
  modules: {
  }
})


export default store