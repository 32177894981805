<template>
    <div class="right">
        <div class="title">{{ $t('el.login.verCodeLogin') }}</div>
        <div class="form">
            <el-form ref="form" :model="form" :rules="rules">
                <el-form-item prop="phone">
                    <el-input prefix-icon="el-icon-phone-outline" v-model.trim="form.phone" :placeholder="$t('el.phoneP')"
                        clearable></el-input>
                </el-form-item>

                <el-form-item prop="code">
                    <div class="flex">
                        <el-input prefix-icon="el-icon-key" v-model.trim="form.code"
                            :placeholder="$t('el.verificationCode')" clearable style="width:260px"></el-input>
                        <div class="getcode" v-if="!tip" @click="getRegisterCode"><img
                                src="@/assets/images/login/email2.png" alt />{{ $t('el.getVerCode') }}</div>
                        <div class="getcode" v-else>{{ number }}s{{ $t('el.reGetVerCode') }}</div>
                    </div>
                </el-form-item>
                <el-button id="login_btn" class="sub-btn" :loading="loading" size="medium" type="primary"
                    style="width: 100%; margin-bottom: 30px" clearable @click.native.prevent="handLogin">
                    <span v-if="!loading">{{ $t('el.login.login') }}</span>
                    <span v-else>{{ $t('el.login.loging') }}...</span>
                </el-button>
            </el-form>
            <div class="right-bottom-btn">
                <div @click="$emit('to', 'login')">{{ $t('el.login.accountLogin') }}</div>
                <div @click="$emit('to', 'register')" style="cursor: pointer;">{{ $t('el.register.register') }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import { loginCode, loginPhone } from "@/api/login";
import { $success, setData } from '@/utils/save'

export default {
    data() {
        const validatePhone = (rule, value, callback) => {
            if (!value) callback(new Error(this.$t('el.phoneP')));
            !this.phoneReg.test(value) ? callback(new Error(this.$t('el.tipPhoneF'))) : callback();
        };
        const validateCode = (rule, value, callback) => {
            value.length === 0 ? callback(new Error(this.$t('el.verCodeP'))) : callback();
        };
        return {
            form: {
                phone: "",
                code: "",
                uuid: "",
            },
            number: '',
            //是否在获取验证码
            tip: false,
            loading: false,
            //验证码url地址
            codeUrl: "",
            rules: {
                phone: [
                    { validator: validatePhone, trigger: 'blur' }
                ],
                code: [
                    { validator: validateCode, trigger: 'blur' }
                ]
            }

        };
    },
    methods: {
        //获得注册的验证码
        getRegisterCode() {
            let self = this;
            const userName = this.form.phone.trim();
            this.$refs["form"].validateField("phone", (error) => {
                if (!error) {
                    loginCode({
                        phoneNumber: userName,
                    }).then((res) => {
                        $success(this.$t('el.getVerCodeSuccess'));
                        this.tip = true;
                        this.form.uuid = res;
                        this.number = 60;
                        self.codeInterval = window.setInterval(() => {
                            if (this.number > 1) {
                                this.number--;
                            } else {
                                window.clearInterval(self.codeInterval);
                                this.tip = false;
                            }
                        }, 1000);
                    });
                }
            });
        },
        //登录
        handLogin() {
            this.$refs['form'].validate(valid => {
                if (valid) {
                    const { phone, code, uuid } = this.form;
                    loginPhone({
                        phonenumber: phone,
                        code,
                        uuid: uuid.data,
                        login_type: 'mobile_phone_code',
                        client_id: 'beosin-vaas',
                        client_secret: '123456',
                        grant_type: 'password',
                        scope: 'server',
                        product_version: 'FREE'
                    }).then(res => {
                        //登录成功
                        setData('token', res.access_token)
                        this.$store.commit("changeUser", res);
                        $success(this.$t('el.login.loginSuccess'));
                        this.$emit("success")
                    }).catch(err => {
                        return false
                    })

                }

            });

        },
    },

};
</script>
<style scoped lang='scss'>
.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;

    .getcode {
        height: 48px;
        font-size: 14px;
        cursor: pointer;
        color: #1cd2a9;
        line-height: 48px;
        text-align: center;
        padding: 0 5px;
        border-radius: 4px;

        img {
            vertical-align: middle;
            margin-right: 3px;
        }
    }
}
</style>