// 在所有文件运行之前运行，处理其他项目传过来的url信息，并且保存到本地


const query = window.location.href.split('?')[1];
if (query) {
   const arr = query.split('&')
   let obj = {}
   for (let i = 0; i < arr.length; i++) {
      const item = arr[i].split('=');
      obj[item[0]] = item[1]
   }
   if (obj.token) {
      obj.token =  decodeURIComponent(obj.token)
      obj.token = obj.token.split(/\s+/g)[1] 
      if(obj.token !== 'null') {
         localStorage.token = obj.token
      } else {
         localStorage.removeItem('token')
      }
   }
   if(obj.lang) {
      localStorage.language = obj.lang
   }
   console.log(obj)
   // 清理参数
   window.location.href = window.location.href.replace(/\?.+$/, '');
}


