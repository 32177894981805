import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { getInfo } from '@/api/login'
Vue.use(VueRouter)
import _this from '../main.js'
import { getData, setData, clearData } from '@/utils/save.js';

const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  //登录页
  {
      path: '/login',
      redirect: "/main",
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/main/index.vue')
  },
  {
    path:'*',
    redirect: "/home"
  },
  {
    path: '/result',
    name: 'result',
    component: () => import('../views/main/components/result.vue')
  },
  // {
  //     path: '/home',
  //     name: 'home',
  //     component: () => import('../views/Home/index.vue')
  // },
  /*{
    path: '/demo',
    name: 'demo',
    component: () => import('../views/demo/index.vue')
  },{
    path: '/pdf',
    name: 'pdf',
    component: () => import('../views/demo/pdf.vue')
  },

  {
    path: '/table',
    name: 'table',
    component: () => import('../views/demo/tab.vue')
  },{
    path: '/tree',
    name: 'tree',
    component: () => import('../views/demo/tree.vue')
  },*/
  // {
  //     path: '/faq',
  //     name: 'faq',
  //     component: () => import('../views/faq.vue')
  // },
  /* {
     path: '/upload',
     name: 'upload',
     component: () => import('../views/demo/fileSubmit.vue')
   }*/

]

const router = new VueRouter({
  routes
})

async function tryGetUserByToken() {
  const realToken = getData('token')
  if (realToken) {
    return getInfo({}).then(res => {
      let result = { ...res.user, username: res.user.userName };
      store.commit('changeUser', result);
    })
      .catch(err => {
        localStorage.removeItem('token');
      })
  }
}
router.beforeEach((to, from, next) => {
  // 获取userInfo by token
  if(!store.state.user && getData('token')) {
    tryGetUserByToken().then(() => {
      next()
    })
  } else {
    next()
  }

})
export default router