import zhLocale from 'element-ui/lib/locale/lang/en'
let obj = {...zhLocale }.el

export const el = {
    ...obj,
    projectName: 'Smart Contract Formal Verification Platform',
    projectName1: '<strong>Smart Contract Formal Verification Platform</strong>',
    title:'Beosin-VaaS',
    changeLog:'Update Log',
    detectDocument:'Documentation',
    lang: 'language',
    file: 'Files',
    fileNoExist: 'The file does not exist',
    back: 'Back',
    feedback:'Feedback',
    tip: 'Tips',
    logout:'Log out',
    confirmLogout:'Are you sure to log out',
    help:'Help',
    saveSuccess: 'Save succeeded',
    resetSuccess: 'Reset succeeded',
    uploadSuccess: 'Upload succeeded',
    submitSuccess: 'Submit succeeded',
    input:'Please enter',
    tipInfo: 'Due to the fact that the system you are currently using is the trial version, your contract is not fully tested. To view the full report, please contact our customer service to upgrade to the Pro version.',
    welcome:'Welcome to VaaS',
    update:{
        title:'CSA version update notes',
        add:'Added features',
        addInfo1:'Added detection type of Fabric and Bcos contracts',
        addInfo2:'Added management function of test project',
        addInfo3:'Added recommendations for fixing test results',
        optimization:'Optimization',
        optimizationInfo1:'Optimized UI visualization and interaction',
        optimizationInfo2:'Optimized report management',

    },
    dialog: {
        confirm: 'Confirm',
        cancel: 'Cancel',
        know: 'I got it',
        submit: 'Submit',
    },
    demo: {
        uploadComp: 'This is an upload component',
        pdf: {
            progress: 'Progress',
            loading: 'Page loaded successfully',
            pageInfo: 'Current page/Total pages',
            pre: 'Previous page',
            next: 'Next page',
            clockwise: 'Clockwise',
            antiClockwise: 'Counterclockwise',
            enlarge: 'Enlarge',
            narrow: 'Narrow',
            download: 'Download',
        },
        tab: {
            selectFirst: 'Select the first one',
            switchTab: 'Switch',
        }
    },
    main: {
        index: {
            saveHover: "Save the current results",
            resetHover: "Reset",
            errorDesc: 'Error description',
            fixAdvice: 'Fix recommendation',
            introduction: "If you need a professional audit report, please contact us for an expert audit.",
            limit: 'You have reached the upper limit of 10 times in a single day',
            contractErr: 'The contract file is empty, please check and retest',
            errDoc:'Click here to view document details.',
            form: {
                placeholder1: "Please select",
                placeholder2: "Please enter the name of the master contract",
                placeholder2E: "Name of the master contract ",// 适配英文显示不全
                placeholder3: "Please enter the master contract deployment parameters",
                placeholder3E: "Master contract deployment parameters",// 适配英文显示不全
                placeholder4: "Please select the compile version",
                placeholder5: "Please select the contract file for detection",
                placeholder5E: "Select the contract file ",// 适配英文显示不全
                placeholder6: "Please enter the file name for query",
            },
            desc: {
                submit: "Start detection",
                remain: "Number of free tests left today:",
                checking: 'Detecting',
                errorF: 'Please modify and resubmit',
                checked: 'Detection completed',
                interrupt: 'Interrupt',
                interruptCheck: 'Interrupt detection',
                stop: 'Stop detection',
                noProblem: 'No issue detected.',
                tips1: 'The following content',
                tips2: 'are only partially displayed,',
                tips3: 'For the full report, please contact our customer service to upgrade to the professional version.',
                reportScan: 'View Report',
                row: 'row',
                word: "times",
                tip: "Tips：",
                result: "This online tool is an experimental version. The test results are for reference only.",
                report: "If you need a professional audit report, please",
                contact: "Contact us",
                desc1: "for an expert audit.",
                desc2: "The results are available in",
                desc3: "“Account > Personal center > CSA testing“",
                desc4: "to check.",
            },
            begin: {
                desc: "In progress",
                step1: "Step 1: Contract compilation",
                return: "Back"
            },
            result: {
                history: 'History',
                label1: 'Detected files',
                label2: 'Project',
                label3: 'Project type',
                label4: 'Master Contract Name',
                label5: 'Master contract deployment parameters',
                label6: 'Compiler version',
                label7: 'Detection start time',
                label8: 'Detection completion time',
                label9: 'Detection status',
                label10: 'Operation',
                page: 'Entries/Page',

                checkDetail:'Details of test results',
                contractName:'Contract name',
                checkTime:'Test time',
                friendlyTips:'Note',
                tip1:'Due to the fact that the system you are currently using is the trial version, your contract is not fully tested. To view the full report, please contact our customer service to upgrade to the Pro version.',
                tip2:'The test results are for reference only and cannot be used as an audit report. If you need a professional audit report, please contact our online advisor.',
                levelStatistics:'Vulnerability level statistics',
                resultStatistics:'Test results statistics',
                support:'Supported by VaaS automatic formal verification tool',
                problemCount:'Number of issues',
                checkOption:'Security test items',
                noProblem:'No issues detected.',
                projectNo:'Project No.',
                resultDesc:'VaaS test results in detail',
                rowCount:'Rows of codes',
                fixAdvice: 'Recommendations',
                lowRisk:'Low-risk',
                mediumRisk:'Medium-risk',
                highRisk:'High-risk',

            }

        },
        leftNav: {
            increament: {
                content1: "Add a new project",
                content2: "Add contract folder",
                content3: "Add contract file",
                content4: "Upload",
            },
            edit:{
                desc1: "Modify the project",
                desc2: "Rename",
                desc3: "Delete",
            },
            create: {
                desc1: "Add new project",
                desc2: "Project name",
                desc3: "Please enter no more than 100 characters",
                desc4: "Project type",
                desc5: "Please select the project type",
                desc6: "Please enter a project name",
                desc7: "New folder",
                desc8: "Folder name",
                desc9: "Please enter a folder name",
                desc10: "Add contract file",
                desc11: "Contract file name",
                desc12: "Please enter the contract file name",
                desc13: "Confirm deletion?",
                desc14: "Cannot be retrieved after deletion",
                desc15: "Cannot include these characters < > / \\ | : * ?. ",
                desc16: "Contract file",
                desc17: "Select the file to upload",
                confirm: "OK",
                success:'Created successfully',
                deleteSuccess:'Deleted successfully',
                renameSuccess:'Rename succeeded',
                editSuccess:'Project has been successfully modified',
                cancel: "Cancel",
                error: {
                    err1: "Please select the item first",
                    err2: "Folder cannot be created under file",
                    err3: "Please select the project or folder first",
                    err4: "File cannot be created under file",
                    err5: "Name cannot be duplicated",
                    err6: "The folder name already exists, please re-enter",
                    err7: "New file cannot be created under file",
                    err8: "The file name already exists, please re-enter",
                    err10:'The maximum creation level has reached the upper limit of 50 levels, and the folder cannot be created',
                    err11:'The maximum creation level of 50 levels has been reached',
                    err12:'Default file, cannot be deleted or modified',
                    err13:'The content has not been modified, please modify it before submitting',
                    err14:'The folder name already exists',
                    err15:'The file name already exists',
                    err16:'The total number of files selected cannot exceed 1MB',
                    err17:'The number of files selected exceeds the limit (10)',
                    err18:'The file type is not the same as the project type, please modify before submiting',
                    err19:'The file name cannot contain < > / \\ | : * ?.',
                    err20:'The file name cannot be more than 100 characters',
                    err21:'The file name already exists, please change it',
                    //    err6:"项目名称不能重复",
                    //    err6:"项目名称不能重复",
                }

            },
            whiteList:{
                defaultProject:'Default project',
                defaultDir:'Default folder',
            }
        }
    },
   
    resetPassword: {
        reset: 'Reset password',
        forgetPW: 'Forgot password',
        reseting: 'Resetting',
        goLogin: 'Already have the account, login now',
        resetSuccess: 'Password confirm success'
    },
    register: {
        register: 'Register',
        registering: 'Registering',
        registerSuccess: 'Register success',
    },
    login: {
        login: 'Login',
        loging: 'Logging in',
        loginOrRegister: 'Login/Register',
        loginSuccess: 'Login successfully',
        accountLogin: 'Account Login',
        verCodeLogin: 'SMS Login',
        accountOrPhone: 'your username/mobile phone number',
        accountOrEmail: 'your email',
        accountOrEmailP: 'Please enter your email',
        accountOrEmailTip:'Email format error',
        accountOrPhoneP: 'Please enter your username/mobile phone number'
    },
    inputPW: 'Please enter your password',
    inputUser: 'Please enter your username',
    getVerCode: 'Send to phone',
    getVerCodeSuccess: 'Send to phone succeeded',
    reGetVerCode: 'Reacquire',
    phoneP: 'Please enter your phone number',
    verCodeP: 'Please enter the verification code',
    verificationCode:'verification code',
    newPWP: "Please enter a new password",
    confirmNewPWP: "Please confirm the new password",
    tipPW: 'The two passwords are inconsistent',
    tipPWF: '6-12 letters + numbers combination, can contain special characters',
    tipPhoneF: 'Wrong format of phone number',
    tipUser: 'English or English+number combination, no more than 15 characters',
    loginG: 'Login expired',
    sysErr: 'System error',
    feedbackDialog:{
        title:'FeedBack',
        content:'Content',
        name:'Your name',
        description:'Description',
        attachment:'Attachment',
        limitSize:'no more than 10M for a single file',
        limitSizeInfo:'Size limit',
        extensions:'rar/zip/doc/jpg/png',
        extensionsInfo:'Support file extensions',
        limitQuantity:'no more than 5',
        limitQuantityInfo:'Quantity limit',
        contentP:'Please enter the content',
        contentP2:'Requirement content cannot exceed 500 characters',
        contentP3:'Please enter the details of your feedback, such as the bugs found in the use of the process, or you would like to put forward better suggestions',
        descriptionP:'Please enter the description',
        descriptionP2:'Requirement description length cannot exceed 500 characters',
        descriptionP3:'Please enter additional information about your feedback, such as the recurrence process of the bugs, or the specific reasons for the request',
        nameP:'Please enter the your name',
        nameP2:'The length of user name cannot exceed 30 characters',
        upload:'upload',
    }
}