import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

let obj = {...zhLocale}.el

export const el = {
    ...obj,
    projectName: '智能合约形式化验证平台',
    projectName1: '<strong>智能合约自动形式化验证平台</strong>',
    title:'成都链安(Beosin)- 让区块链更安全',
    changeLog:'更新日志',
    detectDocument:'文档中心',
    lang: '语言',
    file: '文件',
    fileNoExist: '文件不存在',
    back: '返回',
    feedback:'反馈',
    tip: '提示',
    logout:'退出',
    confirmLogout:'确认退出系统吗',
    help:'帮助',
    saveSuccess: '保存成功',
    resetSuccess: '重置成功',
    uploadSuccess: '上传成功',
    submitSuccess: '提交成功',
    input:'请输入',
    tipInfo: '受限于您当前使用的系统是体验版，您的合约未完全检测。如需查看完整检测报告，请联系链安客服升级至专业版',
    welcome:'欢迎来到链必验',
    update:{
        title:'版本更新说明',
        add:'新增',
        addInfo1:'新增Fabric、Bcos类型合约检测',
        addInfo2:'新增检测项目管理功能',
        addInfo3:'新增检测结果的修复建议',
        optimization:'优化',
        optimizationInfo1:'优化UI视觉及交互',
        optimizationInfo2:'优化检测报告管理方式',

    },
    dialog: {
        confirm: '确认',
        cancel: '取消',
        know: '我知道了',
        submit: '提交',
    },
    demo: {
        uploadComp: '这是个上传组件',
        pdf: {
            progress: '进度',
            loading: '页面加载成功',
            pageInfo: '当前页数/总页数',
            pre: '上一页',
            next: '下一页',
            clockwise: '顺时针',
            antiClockwise: '逆时针',
            enlarge: '放大',
            narrow: '缩小',
            download: '下载',
        },
        tab: {
            selectFirst: '选中第一个',
            switchTab: '切换',
        }
    },
    main: {
        index: {
            saveHover: "保存当前结果",
            resetHover: "重置",
            errorDesc: '错误描述',
            fixAdvice: '修复建议',
            introduction: "如需要专业的审计报告，请联系我们进行专家审计。",
            limit: '今日次数已达单日使用上限10次',
            contractErr: '合约文件为空,请检查后再检测',
            errDoc:'点击此处查看文档详情',
            form: {
                placeholder1: "请选择",
                placeholder2: "请输入主合约名称",
                placeholder2E: "请输入主合约名称",// 适配英文显示不全
                placeholder3: "请输入主合约部署参数",
                placeholder3E: "请输入主合约部署参数",// 适配英文显示不全
                placeholder4: "请选择编译版本",
                placeholder5: "请选择检测合约文件",
                placeholder5E: "请选择检测合约文件",// 适配英文显示不全
                placeholder6: "请输入文件名称进行查询",
            },
            desc: {
                submit: "开始检测",
                remain: "今日剩余免费检测次数：",
                checking: '检测中',
                errorF: '请修改后重新提交',
                checked: '检测完毕',
                interrupt: '中断',
                interruptCheck: '中断检测',
                stop: '停止检测',
                noProblem: '未检测到问题。',
                tips1: '以下只显示',
                tips2: '部分检测内容,',
                tips3: '如需查看完整检测报告，请联系链安客服升级至专业版。',
                reportScan: '查看报告',
                row: '行',
                word: "次",
                tip: "友情提示：",
                result: "该在线工具为体验版，检测结果仅供参考。",
                report: "如需要专业的审计报告，请",
                contact: "联系我们",
                desc1: "进行专家审计。",
                desc2: "检测结果请在",
                desc3: "“账号>个人中心>检测“",
                desc4: "进行查看。",
            },
            begin: {
                desc: "正在进行",
                step1: "步骤1：合约编译",
                return: "返回"
            },
            result: {
                history: '历史记录',
                label1: '检测文件',
                label2: '所属项目',
                label3: '项目类型',
                label4: '主合约名称',
                label5: '主合约部署参数',
                label6: '编译器版本',
                label7: '开始检测时间',
                label8: '检测完成时间',
                label9: '检测状态',
                label10: '操作',
                page: '条/每页',

                checkDetail:'检测结果详情',
                contractName:'合约名称',
                checkTime:'检测时间',
                friendlyTips:'友情提示',
                tip1:'受限于您当前使用的系统是体验版，您的合约未完全检测。如需查看完整检测报告，请联系链安客服升级至专业版。',
                tip2:'检测结果仅供参考，不能作为审计报告。如需专业的审计报告，请联系本公司在线商务进行专家审核。',
                levelStatistics:'漏洞等级统计',
                resultStatistics:'检测结果统计',
                support:'自动形式化验证工具支持',
                problemCount:'问题数量',
                checkOption:'安全检测项',
                noProblem:'未检测到问题。',
                projectNo:'项目编号',
                resultDesc:'检测结果详述',
                rowCount:'代码行数',
                fixAdvice: '修复建议',
                lowRisk:'低危漏洞',
                mediumRisk:'中危漏洞',
                highRisk:'高危漏洞',

            }

        },
        leftNav: {
            increament: {
                content1: "新增项目",
                content2: "新增合约文件夹",
                content3: "新增合约文件",
                content4: "上传",
            },
            edit:{
                desc1: "修改项目",
                desc2: "重命名",
                desc3: "删除",
            },
            create: {
                desc1: "新增项目",
                desc2: "项目名称",
                desc3: "请输入100字符以内",
                desc4: "项目类型",
                desc5: "请选择项目类型",
                desc6: "请输入项目名称",
                desc7: "新建文件夹",
                desc8: "文件夹名称",
                desc9: "请输入文件夹名称",
                desc10: "新增合约文件",
                desc11: "合约文件名称",
                desc12: "请输入合约文件名称",
                desc13: "是否确认删除",
                desc14: "删除后将无法找回",
                desc15: "不能包含这些字符< > / \\ | :  * ?. ",
                desc16: "合约文件",
                desc17: "选择上传的文件",
                confirm: "确定",
                success:'创建成功',
                deleteSuccess:'删除成功',
                renameSuccess:'重命名成功',
                editSuccess:'修改项目成功',
                cancel: "取消",
                error: {
                    err1: "请先选中项目",
                    err2: "文件下不能创建文件夹",
                    err3: "请先选中项目或者文件夹",
                    err4: "文件下不能创建文件",
                    err5: "名称不能重复",
                    err6: "存在同名文件夹，请重新输入",
                    err7: "文件下，不能新建文件",
                    err8: "存在相同的文件名称，请重新输入",
                    err10:'已达最大创建层级上限50层,不能创建文件夹',
                    err11:'已达最大创建层级上限50层',
                    err12:'默认文件,不可删除或修改',
                    err13:'未修改内容，请修改后再提交',
                    err14:'同名文件夹已存在',
                    err15:'同名文件已存在',
                    err16:'所选文件总量不可超过1MB',
                    err17:'所选文件数量超出限制（10个）',
                    err18:'文件类型与项目类型不一致，请修改后提交',
                    err19:'文件名不能包含< > / \\ | :  * ?.',
                    err20:'文件名不能超过100个字符',
                    err21:'文件名已存在,请修改',
                    //    err6:"项目名称不能重复",
                    //    err6:"项目名称不能重复",
                }

            },
            whiteList:{
                defaultProject:'默认项目',
                defaultDir:'默认文件夹',
            }
        }
    },

    resetPassword: {
        reset: '重置密码',
        forgetPW: '忘记密码',
        reseting: '重置中',
        goLogin: '已有账户去登录',
        resetSuccess: '重置密码成功'
    },
    register: {
        register: '注册',
        registering: '注册中',
        registerSuccess: '注册成功',
    },
    login: {
        login: '登 录',
        loginOrRegister: '登录/注册',
        loging: '登 录 中',
        loginSuccess: '登录成功',
        accountLogin: '账号密码登录',
        verCodeLogin: '手机验证码登录',
        accountOrEmail: '请输入邮箱',
        accountOrEmailP: '请输入邮箱',
        accountOrEmailTip:'邮箱格式错误',
        accountTip:'手机格式错误',
        accountOrPhone: '请输入用户名/手机号',
        accountOrPhoneP:'请输入用户名/手机号'
    },
    inputPW: '请输入密码',
    inputUser: '请输入用户名',
    getVerCode: '获取验证码',
    getVerCodeSuccess: '获取验证码成功',
    reGetVerCode: '后重新获取',
    phoneP: '请输入手机号',
    verCodeP: '请输入验证码',
    verificationCode:'请输入验证码',// 英文用
    newPWP: "请输入新密码",
    confirmNewPWP: "请确认新密码",
    tipPW: '两次输入密码不一致',
    tipPWF: '6-12位字母+数字组合，可含特殊字符',
    tipPhoneF: '手机号格式错误',
    tipUser: '英文或英文+数字组合，不超过15个字符',
    loginG: '登录过期',
    sysErr: '系统错误',
    feedbackDialog:{
        title:'需求反馈',
        content:'内容',
        name:'姓名',
        description:'描述',
        attachment:'附件',
        limitSize:'单个文件不超过10M',
        limitSizeInfo:'大小限制',
        extensions:'rar/zip/doc/jpg/png',
        extensionsInfo:'支持文件格式',
        limitQuantity:'不超过5个',
        limitQuantityInfo:'数量限制',
        contentP:'请输入内容',
        contentP2:'内容长度不能超过500个字符',
        contentP3:'请输入您要反馈的详细内容。如在使用过程中，发现的产品bug，或您想提出更好的需求建议',
        descriptionP:'请输入描述',
        descriptionP2:'描述长度不能超过500个字符',
        descriptionP3:'请输入您反馈内容的补充信息。如产品bug的复现过程，建议诉求产生的具体原因',
        nameP:'请输入姓名',
        nameP2:'姓名长度不能超过30个字符',
        upload:'点击上传',
    }
}