/*
 * @Author: yinian430 
 * @Date: 2020-07-08 16:19:51 
 * @Last Modified by: yinian430
 * @Last Modified time: 2020-11-23 15:35:54
 */
import axios from 'axios'
import { Message } from 'element-ui'
import qs from 'qs'
import { getStore } from "./utils";
import router from '@/router'
import _this from '../main.js'
let num = 1;
const service = axios.create({

    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 50000 // request timeout
})

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// request interceptor
service.interceptors.request.use(
    config => {
        config.headers['Accept-Language'] = getStore('language') === null ? 'en_US' : getStore('language')
        if (localStorage.getItem('token')) {
            config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        }
        const M = ['post', 'delete', 'put'];
        if (M.includes(config.method) && config.url !== '/auth/oauth/login') {
            config.data = config.params;
            if (!config.headers['Content-Type']) {
                config.headers['Content-Type'] = 'application/json;charset=UTF-8'
                config.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
                if (config.data && config.data.notJson) {
                    delete config.data
                }
            }
            delete config.params
        }
        return config
    },
    error => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data;
        console.log(res)
        //登录过期
        if (res.code === 401 || res.code === 920000003) {
            localStorage.removeItem('token');
            _this.$store.commit('changeShowResult', false)
            if(_this.$store.state.user) {
                // 中途过期
                Message({
                    customClass:'login-expired-message',
                    message: getStore('language') === 'zh_CN' ? '登录过期' : 'Login expired',
                    type: 'error',
                    duration: 1000
                })
                setTimeout(() => {
                    window.location.reload()
                }, 1000)
                _this.$store.state.user = null
            }

            throw res
        }
        // 针对需求反馈特殊处理
        if (response.config.url === '/api/af/help/require/feedback/add') {
            if ((res.code !== 200 && res.code !== '100000000')) {
                return Promise.reject(new Error(res.msg || res.message || 'Error'))
            } else {
                return res;
            }
        }
        
        if (response.status !== 200) {
            Message({
                message: res.msg,
                type: 'error',
                duration: 5 * 1000
            })
        } else {

            if (res.code !== 200 && res.code !== '100000000') {
                Message({
                    message: res.msg || 'Error check your token or method',
                    type: 'error',
                    duration: 2 * 1000
                })

                return Promise.reject(new Error(res.msg || res.message || 'Error'))
            } else {
                // if (res.total || res.total === 0 || (res.img && res.uuid)) {
                //     return res
                // }
                return res;
                
            }
        }
    },
    error => {
        if (error.response.status == '401' || error.response.status == '403') {
            localStorage.removeItem('token');
            Message({
                message: res.msg || '权限不够',
                type: 'error',
                duration: 2 * 1000
            })
        } else {
            console.log('err' + error) // for debug
            Message({
                message: error.msg,
                type: 'error',
                duration: 2 * 1000
            })
        }
        return Promise.reject(error)
    }
)


const noToken = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 50000 // request timeout
})

noToken.interceptors.request.use(
    config => {
        config.headers['Accept-Language'] = getStore('language') === null ? 'en_US' : getStore('language')
        return config
    },
    error => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
)
noToken.interceptors.response.use(response => {
    const res = response.data;
    if (response.status !== 200) {
        Message({
            message: res.msg,
            type: 'error',
            duration: 5 * 1000
        })
        throw res
    }
    return res
})

export default service.request
export {
    noToken
}