import self from '@/main'
/***
 * 从locolstorage中获得数据
 */
export const getData = (name) => {
   return localStorage.getItem(name)

}
/***
 * 更改locolStorage中的数据
 * 
 */
export const setData = (name, value) => {
   localStorage.setItem(name, value)

}
/***
 * 清除locolStorage中的数据
 */
export const clearData = (name) => {
   localStorage.removeItem(name)
}
/****
 * success 
 */
export const $success = (message) => {
   return self.$message({
      showClose: true,
      message,
      duration: 2000,
      type: "success"
   })
}
/***
 * warning
 */
export const $warning = (message) => {
   return self.$message({
      showClose: true,
      message,
      duration: 2000,
      type: "warning"
   })
}
/***
 * error
 */
export const $error = (message) => {
   return self.$message({
      showClose: true,
      message,
      duration: 2000,
      type: "error"
   })
}
/***
 * 文件夹和文件命名规则
 */
export const fileRules = (word) => {
   let saveWord = ["?", "\\", "*", "|", "<", ">", "“", ":", "“", "\/", "？",'.'];
   return saveWord.some(item => word.indexOf(item) !== -1)
}
/***
 * 将字符串转换为路径数组
 */
export const concatPath = (str) => {
   const arr = str.split('/');
   let result = [];
   arr.forEach((item, i) => {
      let cur = arr.filter((v, t) => t <= i).join('/');
      result.push(cur)
   });
   return result;
}
/***
 * 将数组以某个key分类
 */
export const groupArray=(arr,key)=>{
   const result=[];
  arr.forEach(item=>{
      let num;
      const flag=result.some((v,i)=>{
            num=i;
          return  v[key]===item[key];
      });
      if(!flag){
          result.push({[key]:item[key],data:[{...item}]})
      }else{
         result[num].data.push(item)
      }
  })
  return result;

 }
 /***
  * 格式化文件地址
  * 输入'abcdefghjklmn/abcdefghjklmn/abcdefghjklmn/ab.cd'
  * 输出 abcd.../abcd.../abcd.../ab.cd
  */
 export  const foramtStr=(str)=>{
   const  arr=str.split('/');
  let resultStr='';
  arr.forEach((v,i)=>{
       if(i<arr.length-1){
           if(v.length>4){
               resultStr=resultStr+v.substr(0,4)+'...'+'/'
           }else{
               resultStr=resultStr+v+'/'
           }
       }else{
         let last=v.lastIndexOf('.');
         let ext=v.substr(last);
         if(last>3){
             resultStr=resultStr+v.substr(0,4)+'...'+ext
         }else{
             resultStr+=v;
         }
       }
  })
      return resultStr;
  }
  /*****
   * str 字符串
   * len 长度 英文1字符中文2字符
   * 
   */
  export const absoluteLen=(str,len)=>{
   let num=0;
let count=0;
for(let i=0;i<str.length;i++){
   num=i;
   count+= str.charCodeAt(i)>255?2:1;
   if(count>=len){
       return str.substring(0,num);
   }
}
 return str;
}
/***
 *  str 字符串
 * len 长度 英文1字符中文2字符,
 * 这中更具绝对长度判断是否显示气泡
 */

 export const absolutFlag=(str,len)=>{
   let num=0;
let count=0;
for(let i=0;i<str.length;i++){
   num=i;
   count+= str.charCodeAt(i)>255?2:1;
   if(count>=len){
       return true;
   }
}
 return false;
}
/**
 * 
 * @param {字符串} str 
 *
 * @returns  字符串长度
 */
export const strLen=(str)=>{
let count=0;
for(let i=0;i<str.length;i++){
   count+= str.charCodeAt(i)>255?2:1;
}
 return count;
}