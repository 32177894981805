/*
* @utils.js
* @deprecated 
* @author czh
* @update (czh 2021/12/28)
*/
import Vue from 'vue'
/**
 * 存储localStorage
 */
export const setStore = (name, content) => {
    if (!name) return;
    if (typeof content !== 'string') {
        content = JSON.stringify(content);
    }
    window.localStorage.setItem(name, content);
}
Vue.prototype.setStore = setStore

/**
 * 获取localStorage
 */
export const getStore = (name )=>{
    if (!name) return;
    return window.localStorage.getItem(name);
}
Vue.prototype.getStore = getStore

/**
 * 删除localStorage
 */
export const removeStore = (name )=>{
    if (!name) return;
    window.localStorage.removeItem(name);
}
Vue.prototype.removeStore = removeStore

export const getQueryVariable = (variable) =>{
    let query = window.location.href
    let vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] == variable) {
            return pair[1];
        }
    }
    return (false);
}
Vue.prototype.getQueryVariable = getQueryVariable

export const getUrlkey = (url) =>{
    let params = {};
    if(url.indexOf("?")>-1){//判断如果请求地址中包含参数
        let urls = url.split("?");
        let arr = urls[1].split("&");
        for (var i = 0, l = arr.length; i < l; i++) {
            var a = arr[i].split("=");
            params[a[0]] = a[1];
        }
    }
    return params;
}
Vue.prototype.getUrlkey = getUrlkey