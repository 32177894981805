<template>
    <div class="right">
        <template v-if="!isSuccess">
            <div class="title">{{ $t('el.register.register') }}</div>
            <!-- <div class="title">VaaS</div>
            <div class="topic">链必验-智能合约形式化验证平台</div>-->
            <div class="form">
                <el-form ref="form" :model="form" :rules="rules">
                    <el-form-item prop="username">
                        <el-input prefix-icon="el-icon-user" v-model.trim="form.username"
                            :placeholder="$t('el.inputUser')" clearable maxlength="20"></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input prefix-icon="el-icon-lock" v-model.trim="form.password"
                            :placeholder="$t('el.inputPW')" maxlength="12" :type="visibleAgain ? 'text' : 'password'">
                            <template slot="append">
                                <img class="showIcon" v-if="!visibleAgain" @click="visibleAgain = !visibleAgain"
                                    src="@/assets/images/hide.png" alt="">
                                <img class="showIcon" v-else @click="visibleAgain = !visibleAgain"
                                    src="@/assets/images/show.png" alt="">
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="confirmPassword">
                        <el-input prefix-icon="el-icon-lock" v-model.trim="form.confirmPassword"
                            :placeholder="$t('el.confirmNewPWP')" maxlength="12" :type="visible ? 'text' : 'password'">

                            <template slot="append">
                                <img class="showIcon" v-if="!visible" @click="visible = !visible"
                                    src="@/assets/images/hide.png" alt="">
                                <img class="showIcon" v-else @click="visible = !visible" src="@/assets/images/show.png"
                                    alt="">
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="phone">
                        <el-input prefix-icon="el-icon-phone-outline" v-model.trim="form.phone"
                            :placeholder="$t('el.phoneP')" clearable maxlength="16"></el-input>
                    </el-form-item>

                    <el-form-item prop="code">
                        <div class="flex">
                            <el-input prefix-icon="el-icon-key" v-model.trim="form.code"
                                :placeholder="$t('el.verificationCode')" style="width: 260px" clearable maxlength="6">
                            </el-input>
                            <div class="getcode" v-if="!tip" @click="getCode">
                                <img src="@/assets/images/login/email2.png" alt />
                                {{ $t('el.getVerCode') }}
                            </div>
                            <div class="getcode" v-else>{{ number }}s {{ $t('el.reGetVerCode') }}</div>

                        </div>
                    </el-form-item>

                    <el-button id="login_btn" :loading="loading" size="medium" type="primary" class="sub-btn"
                        style="width: 100%; margin-bottom: 30px" @click.native.prevent="handRegister">
                        <span v-if="!loading">{{ $t('el.register.register') }}</span>
                        <span v-else>{{ $t('el.register.registering') }}...</span>
                    </el-button>
                </el-form>
                <div class="right-bottom-btn">
                    <div @click="$emit('to', 'login')">{{ $t('el.resetPassword.goLogin') }}</div>
                </div>
            </div>
        </template>
        <Success v-else :title="$t('el.register.registerSuccess')" @to="$emit('to', 'login')"></Success>
    </div>
</template>
<script>
import { registerUser, getRegCode } from "@/api/login";
import { Message } from "element-ui";
import Success from './success.vue'
export default {
    components:{
        Success
    },
    data() {
        const validateUsername = (rule, value, callback) => {
            if (!value) {
                callback(new Error(this.$t('el.inputUser')));
            } else if (!this.nameReg.test(value)) {
                callback(new Error(this.$t('el.tipUser')));
            } else {
                callback();
            }
        };
        const validatePassword = (rule, value, callback) => {
            if (!value) {
                callback(new Error(this.$t('el.inputPW')));
            } else if (!this.pwdReg.test(value)) {
                callback(new Error(this.$t('el.tipPWF')));
            } else {
                callback();
            }
        };
        const validateCode = (rule, value, callback) => {
            if (value.length == 0) {
                callback(new Error(this.$t('el.verCodeP')));
            } else {
                callback();
            }
        };
        const validateConfirmPassword = (rule, value, callback) => {
            if (!value) {
                callback(new Error(this.$t('el.confirmNewPWP')));
            } else if (!this.pwdReg.test(value)) {
                callback(new Error(this.$t('el.tipPWF')));
            } else {
                if (this.form.password === this.form.confirmPassword) {
                    callback();
                } else {
                    callback(new Error(this.$t('el.tipPW')));
                }
            }
        };
        const validateCPhone = (rule, value, callback) => {
            if (!value) {
                callback(new Error(this.$t('el.phoneP')));
            } else if (!this.phoneReg.test(value)) {
                callback(new Error(this.$t('el.tipPhoneF')));
            } else {
                callback();
            }
        };
        return {
            form: {
                username: "",
                password: "",
                confirmPassword: "",
                phone: "",
                code: "",
                uuid: "",
            },
            loading: false,
            rules: {
                username: [
                    { required: true, trigger: "blur", validator: validateUsername },
                ],
                password: [
                    { required: true, trigger: "blur", validator: validatePassword },
                ],
                confirmPassword: [
                    {
                        required: true,
                        trigger: "blur",
                        validator: validateConfirmPassword,
                    },
                ],
                code: [{ required: true, trigger: "blur", validator: validateCode }],
                phone: [{ required: true, trigger: "blur", validator: validateCPhone }],
            },
            //是否重新获得验证码
            tip: false,
            number: null,
            visibleAgain: false,
            visible: false,
            isSuccess: false
        };
    },
    methods: {
        //处理注册
        handRegister() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    const { username, password, code, uuid, phone } = this.form;
                    this.loading = true;
                    registerUser({
                        userName: username,
                        phonenumber: phone,
                        uuid,
                        code,
                        password,
                        sourceCode: "vaas_system",
                    })
                        .then((res) => {
                            //注册成功
                            Message.success(this.$t('el.register.registerSuccess'));
                            this.loading = false;
                            this.isSuccess = true
                        })
                        .catch((err) => {
                            this.loading = false;
                            return false;
                        });
                }
            });
        },
        //获得注册的验证码
        getCode() {
            this.$refs["form"].validateField("phone", (error) => {
                if (!error) {
                    getRegCode({
                        userName: this.form.phone,
                    }).then((res) => {
                        this.$message.success(this.$t('el.getVerCodeSuccess'));
                        console.log(res, "res");
                        this.tip = true;
                        this.form.uuid = res.data;
                        this.number = 60;
                        const codeInterval = setInterval(() => {
                            if (this.number > 0) {
                                this.number--;
                            } else {
                                clearInterval(codeInterval);
                                this.tip = false;
                            }
                        }, 1000);
                    });
                }
            });
        },
    },
};
</script>
<style scoped lang='scss'>
.title{
    margin-top: 37px !important;
}
.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;

    .getcode {
        height: 48px;
        font-size: 14px;
        cursor: pointer;
        color: #1cd2a9;
        line-height: 48px;
        text-align: center;
        padding: 0 5px;
        border-radius: 4px;

        img {
            vertical-align: middle;
            margin-right: 3px;
        }
    }
}
</style>