/*
 * @Author: yinian430 
 * @Date: 2020-07-15 19:06:52 
 * @Last Modified by: yinian430
 * @Last Modified time: 2021-03-30 16:05:03
 */
import Vue from 'vue'

//邮箱
Vue.prototype.emailReg = /^(\w)+((\.\w+)|(\-\w+))*@(\w|\-)+((\.\w+)+)$/;
//手机
Vue.prototype.phoneReg =  /^1\d{10}$/;
//中文与空格
Vue.prototype.hzReg = new RegExp("[\u4e00-\u9fa5]");
//空格
Vue.prototype.spaceReg = new RegExp("\\s");
//密码
Vue.prototype.pwdReg = new RegExp("^[0-9a-zA-Z,./?@#$%，。、？~`！￥…&*（）()+_‘’|；:;!,\",\'-~]{6,12}$");
//用户名
Vue.prototype.nameReg = /^(?!\d+$)[\da-zA-Z]{1,10}$/;