<template>
    <div class="success">
        <img src="@/assets/images/login/success.png" alt />
        <div class="title">{{ title || '' }}</div>
        <el-button
            id="login_btn"
            size="medium"
            type="primary"
            style="width: 100%; margin-bottom: 30px"
            @click="$emit('to', 'login')"
            class="sub-btn"
        >
            <span>{{ $t('el.login.login') }}</span>
        </el-button>
    </div>
</template>

<script>
export default {
    props: {
        title: String
    }
}
</script>

<style lang="scss" scoped>
.success {
    margin-top: 153px;
    text-align: center;
}
</style>